import React from "react"
import { Modal } from "react-bootstrap"

const ZapierPopup = ({ modalShow, setModalShow }) => {
  return (
    <Modal show={modalShow} onHide={() => setModalShow(false)}>
      <Modal.Header>
        <h1 className="modal-title fs-5" id="ZapierAuthModalLabel">
          <strong>Z</strong> Connect with Zapier
        </h1>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => setModalShow(false)}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-lg-12 text-center">
            <strong>Coming soon</strong>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-default"
          data-bs-dismiss="modal"
          onClick={() => setModalShow(false)}
        >
          <i className="bi bi-x-lg"></i> Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ZapierPopup
