import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap"

import EditProject from "./editProject"
import AxiosInstance from "../../../../services/axiosInstance"

const Project = ({ icons }) => {
  const [projectTypes, setProjectTypes] = useState([])
  const [assignBlock, setAssignBlock] = useState("")
  const [tagColor, setTagColor] = useState("")
  const [assignTagsId, setAssignTagsId] = useState([])
  const [assignTag, setAssignTag] = useState([])
  const [assignTags, setAssignTags] = useState([])
  const [projectForm, setProjectForm] = useState(false)
  const [industryType, setIndustryType] = useState("")
  const [industryIcon, setIndustryIcon] = useState("0-circle")
  const [projectId, setProjectId] = useState("")
  const [reloadData, setReloadData] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [iconColor, setIconColor] = useState("black")
  const [iconBgColor, setIconBgColor] = useState("grey")

  const handleAdd = () => {
    setProjectForm(true)
    setProjectId("")
    setIndustryType("")
    setIndustryIcon("0-circle")
  }
  const handleEdit = id => {
    setProjectForm(true)

    let filterData = projectTypes.filter(data => {
      return data.id === id
    })
    setProjectId(filterData[0])
    setIndustryType(filterData[0].dealTypeLabel)
    setIndustryIcon(filterData[0].icon)
    setIconColor(filterData[0].iconColor)
    setIconBgColor(filterData[0].iconBackgroundColor)
  }

  const projectTypesData = () => {
    AxiosInstance.get("/settings/transaction-type")
      .then(response => {
        setProjectTypes(response.data.items)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleDelete = Id => {
    AxiosInstance.delete(`/settings/transaction-type/${Id}`)
      .then(response => {
        if (response.status === 200) {
          projectTypesData()
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          alert(error.response.data)
        }
        console.log(error)
      })
  }

  const handlePrimary = Id => {
    AxiosInstance.post(`/settings/transaction-type/make-primary/${Id}`)
      .then(response => {
        if (response.status === 200) {
          projectTypesData()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    projectTypesData()
  }, [])

  useEffect(() => {
    if (reloadData === true) {
      projectTypesData()
      setReloadData(false)
    }
  })

  const handleAssignBlock = tagName => {
    setAssignBlock(tagName)
  }

  const handleTagColor = (tagType, tag, tags, tagsId) => {
    setAssignTags(tags)
    setAssignTag([tag])
    let filterTable = projectTypes.filter(tableData => {
      return tableData.id == tagsId
    })
    setAssignTagsId(filterTable)
    let type = tagType
    switch (type) {
      case "blue":
        setTagColor("blue")
        break
      case "pink":
        setTagColor("pink")
        break
      case "beige":
        setTagColor("beige")
        break
      case "green":
        setTagColor("green")
        break
      case "gray":
        setTagColor("gray")
        break
      default:
        setTagColor("blue")
    }
  }

  return (
    <Accordion.Item eventKey="3">
      <Accordion.Header data-bs-target="#taskJobSetting" aria-controls="taskJobSetting">
        <i className="bi bi-person-fill-check me-2" />
        Job / Project / Sales Opportunity Types
        <Link to="">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                A task or project can only be in 1 category, but can have many tags. Status is specific to
                category.
              </Tooltip>
            }
          >
            <i className="bi bi-question-circle-fill ms-1" />
          </OverlayTrigger>
        </Link>
      </Accordion.Header>
      <Accordion.Body id="taskJobSetting" aria-labelledby="taskJobSetting" data-bs-parent="#generalSettings">
        <div className="row p-3">
          <div className="col-lg-10 col-sm-12 col-md-12 col-xs-12 mx-auto">
            {projectForm === true && (
              <EditProject
                setProjectForm={setProjectForm}
                projectId={projectId}
                industryType={industryType}
                setIndustryType={setIndustryType}
                industryIcon={industryIcon}
                setIndustryIcon={setIndustryIcon}
                icons={icons}
                setReloadData={setReloadData}
                showModal={showModal}
                setShowModal={setShowModal}
                iconColor={iconColor}
                setIconColor={setIconColor}
                iconBgColor={iconBgColor}
                setIconBgColor={setIconBgColor}
              />
            )}
            <div className="row mb-2 align-items-center">
              <div className="col-md-8">
                <small>
                  <i className="bi bi-person-fill-check" />
                  What services do you sell? What pipelines of common project types you want to automate?
                </small>
              </div>
              <div className="col-md-4 text-end">
                <button className="btn btn-primary btnAddNewTransactionType" onClick={handleAdd}>
                  <i className="bi bi-plus-lg" /> Add New
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table
                className="table table-striped dataTable no-footer"
                id="tblTransactionTypes"
                width="100%"
              >
                <thead>
                  <tr>
                    <th width="5%">&nbsp;</th>
                    <th width="75%">Transaction Type</th>
                    <th width="20%" className="text-end">
                      Default Role(s)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {projectTypes.length === 0 && (
                    <tr>
                      <td colSpan={4} className="text-center">
                        No Project / Job / Sales Opportunity Types added yet. Want to
                        <a
                          style={{ color: "#125fad", cursor: "pointer" }}
                          onClick={() => setProjectForm(true)}
                        >
                          {" "}
                          click here & add one{" "}
                        </a>{" "}
                        now?
                      </td>
                    </tr>
                  )}
                  {projectTypes.map((projectType, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-center tableColChkDel">
                          <div className="tableColChkDelBox">
                            <input
                              type="checkbox"
                              name="fileid[]"
                              id={`PJSO_ID${projectType.id}`}
                              className="css-checkbox"
                            />
                            <label
                              htmlFor={`PJSO_ID${projectType.id}`}
                              className="css-checkbox-label"
                            ></label>
                            <span
                              className={`tableColChkSpan color-${projectType.iconColor}`}
                              style={{ backgroundColor: projectType.iconBackgroundColor }}
                            >
                              <i className={`bi bi-${projectType.icon}`}></i>
                            </span>
                          </div>
                        </td>

                        <td>
                          <Link to={`/settings/business/jobtype/${projectType.id}`} target="_blank">
                            {projectType.dealTypeLabel}
                          </Link>{" "}
                          <div className="SwiftCloudTableTags">
                            <ul>
                              {projectType.tags.map((tags, tagIndex) => {
                                return (
                                  <li
                                    key={tagIndex}
                                    className={assignBlock == tags ? `tag-${tagColor}` : `tag-${tags.color}`}
                                    onMouseEnter={() => handleAssignBlock(tags)}
                                    onMouseLeave={() => setAssignBlock("")}
                                  >
                                    <a href="#">{tags.tag}</a>
                                    {assignBlock == tags && (
                                      <div className="SwiftCloudTagScore">
                                        <div className="mb-2 SwiftCloudTagScoreType">
                                          <a
                                            className="SwiftCloudTagScoreBlue"
                                            onClick={() => {
                                              handleTagColor("blue", tags, projectTypes.tags, projectTypes.id)
                                            }}
                                            data-type="blue"
                                          />
                                          <a
                                            className="SwiftCloudTagScorePink"
                                            onClick={() => {
                                              handleTagColor("pink", tags, projectTypes.tags, projectTypes.id)
                                            }}
                                            data-type="pink"
                                          />
                                          <a
                                            className="SwiftCloudTagScoreBeige"
                                            onClick={() =>
                                              handleTagColor(
                                                "beige",
                                                tags,
                                                projectTypes.tags,
                                                projectTypes.id
                                              )
                                            }
                                            data-type="beige"
                                          />
                                          <a
                                            className="SwiftCloudTagScoreGreen"
                                            onClick={() =>
                                              handleTagColor(
                                                "green",
                                                tags,
                                                projectTypes.tags,
                                                projectTypes.id
                                              )
                                            }
                                            data-type="green"
                                          />
                                          <a
                                            className="SwiftCloudTagScoreGray"
                                            onClick={() =>
                                              handleTagColor("gray", tags, projectTypes.tags, projectTypes.id)
                                            }
                                            data-type="gray"
                                          />
                                        </div>
                                        <span>Assign Tag Score / Value</span>
                                        <div className="input-group">
                                          <input type="number" className="form-control tagScoreInput" />
                                          <button
                                            type="button"
                                            className="btn btn-sm btn-success btnSaveTagScore"
                                          >
                                            <i className="bi bi-check2" />
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                        </td>
                        <td className="text-end tableColAction d-none d-md-table-cell">
                          {!projectType.isPrimary ? (
                            <a
                              className="btn btn-secondary"
                              onClick={() => {
                                handlePrimary(projectType.id)
                              }}
                            >
                              <OverlayTrigger placement="top" overlay={<Tooltip>Make Primary</Tooltip>}>
                                <i className="bi bi-check2" />
                              </OverlayTrigger>
                            </a>
                          ) : (
                            <a
                              className="btn btn-sm btn-success"
                              onClick={() => {
                                return false
                              }}
                            >
                              <OverlayTrigger placement="top" overlay={<Tooltip>Primary</Tooltip>}>
                                <i className="bi bi-check2" />
                              </OverlayTrigger>
                            </a>
                          )}{" "}
                          <a
                            className="btn btn-edit"
                            onClick={() => {
                              handleEdit(projectType.id)
                            }}
                          >
                            <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                              <i className="bi bi-pencil-fill" />
                            </OverlayTrigger>
                          </a>{" "}
                          <a
                            className="btn btn-delete"
                            onClick={() => {
                              handleDelete(projectType.id)
                            }}
                          >
                            <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                              <i className="bi bi-trash-fill" />
                            </OverlayTrigger>
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default Project
