import React, { useEffect } from "react"

const TagRoleField = ({
  addTag,
  setAddTag,
  newTag,
  setNewTag,
  tempTags,
  setTempTags,
  removeTagIndex,
  setRemoveTagIndex,
}) => {
  useEffect(() => {
    if (addTag === true) {
      setAddTag(false)
      let filterTag = tempTags.filter(tags => {
        return tags.tag === newTag
      })
      if (filterTag.length === 0) {
        // setAddTag(false)
        setTempTags([
          ...tempTags,
          {
            tag: newTag,
            score: 0,
            color: "blue",
          },
        ])
        setNewTag("")
      }
    }

    if (removeTagIndex) {
      setRemoveTagIndex(null)
      let tempTag = []

      tempTags.map((tagArray, arrayIndex) => {
        if (parseInt(removeTagIndex) !== arrayIndex) {
          tempTag = [...tempTag, tagArray]
        }
        if (arrayIndex === tempTags.length - 1) {
          setTempTags([...tempTag])
        }
      })
    }
  })

  return (
    <div className="row mb-3 align-items-center">
      <div className="col-sm-4">Default Role Tags</div>
      <div className="col-sm-8">
        <div className="bootstrap-tagsinput">
          {tempTags.length > 0 &&
            tempTags.map((tagDetail, tagIndex) => (
              <span className="tag label label-info" key={tagDetail.tag}>
                {tagDetail.tag}
                <span data-role="remove" onClick={() => setRemoveTagIndex(`${tagIndex}`)} />
                <span data-role="drag" />
              </span>
            ))}
          <input
            type="text"
            placeholder="Enter a #tag then click Tab"
            size="27"
            value={newTag}
            onKeyDown={e => {
              if (e.code === "Tab" || e.code === "Enter") {
                if (tempTags.length === 0) {
                  setAddTag(false)
                  setTempTags([
                    ...tempTags,
                    {
                      tag: newTag,
                      score: 0,
                      color: "blue",
                    },
                  ])
                  setNewTag("")
                } else {
                  setAddTag(true)
                }
              }
            }}
            onChange={e => {
              setNewTag(e.target.value)
            }}
          />
          <button className="btn btn-sm btn-primary btnSaveTag">
            <i className="fa fa-save"></i>
          </button>
        </div>
        <input
          type="text"
          name="Default_Role_Tags"
          id="Default_Role_Tags"
          className="form-control"
          data-toggle="tagsinput"
          style={{ display: "none" }}
        />
      </div>
    </div>
  )
}
export default TagRoleField
