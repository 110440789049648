import React, { useEffect, useState } from "react"
import EditTagField from "./editTagField"
import AxiosInstance from "../../../../services/axiosInstance"

const EditRaveScore = ({
  setEditForm,
  setTableLoad,
  filterRoleTableId,
  tempTagsEdit,
  setTempTagsEdit,
  role,
  setRole,
}) => {
  // const [role, setRole] = useState("")
  const [addTag, setAddTag] = useState(false),
    [newTag, setNewTag] = useState(""),
    [removeTagIndex, setRemoveTagIndex] = useState(null)
  const handleEdit = () => {
    if (filterRoleTableId != null) {
      AxiosInstance.post(`/settings/role/edit/${filterRoleTableId[0].id}`, {
        role: role == "" ? filterRoleTableId[0].role : role,
        tags: tempTagsEdit,
      })
        .then(response => {
          if (response.status == 200) {
            setTableLoad(true)
            setRole("")
            setNewTag("")
            setEditForm(false)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  return (
    <div className="row">
      <div className="col-lg-6 col-md-8 mx-auto py-4 " id="addNewRoleContainer">
        <div className="row">
          <div className="col-lg-12 colHeading">
            <strong>
              <i className="bi bi-plus-lg"></i> Edit RAVE Score Automation
            </strong>
          </div>
          <div className="col-lg-12 dh-block-body">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row mb-3 align-items-center">
                      <div className="col-sm-4">
                        Role{" "}
                        <span
                          data-bs-toggle="tooltip"
                          data-bs-title="i.e. Patient, Borrower, Client, Customer... just for you."
                          title="i.e. Patient, Borrower, Client, Customer... just for you."
                        >
                          <i className="bi bi-question-circle-fill"></i>
                        </span>
                      </div>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          name="role_field"
                          id="role_field"
                          className="form-control"
                          value={role}
                          onChange={e => {
                            setRole(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <EditTagField
                      addTag={addTag}
                      setAddTag={setAddTag}
                      newTag={newTag}
                      setNewTag={setNewTag}
                      tempTagsEdit={tempTagsEdit}
                      setTempTagsEdit={setTempTagsEdit}
                      removeTagIndex={removeTagIndex}
                      setRemoveTagIndex={setRemoveTagIndex}
                    />
                    <div className="row">
                      <div className="col-sm-12 text-end">
                        <button
                          type="button"
                          name="btnCancel"
                          id="btnCancelNewRole"
                          className="btn btn-default"
                          onClick={() => setEditForm(false)}
                        >
                          <i className="bi bi-x-lg"></i>
                          Cancel
                        </button>{" "}
                        <button
                          type="button"
                          name="btnSave"
                          id="btnSaveNewRole"
                          className="btn btn-primary"
                          onClick={handleEdit}
                        >
                          <i className="bi bi-check2"></i>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditRaveScore
