import * as React from "react"
import AxiosInstance from "../../services/axiosInstance"

export const NUMBER_RATTING = "number_ratting"
export const STAR_RATTING = "star_ratting"
export const SLIDER_RATTING = "slider_ratting"
export const CUSTOM_URL = "url"
export const CUSTOM_TEXT = "text"
export const CUSTOM_NUMBER = "number"
export const CUSTOM_ADDRESS = "address"
export const CUSTOM_OPTIONS = "options"
export const CUSTOM_TEXTAREA = "textarea"
export const CUSTOM_TEXTBOX = "textbox"
export const CUSTOM_EMAIL = "email"

export const CONTACT_TYPE_HUMAN = "human"
export const CONTACT_TYPE_COMPANY = "company"
export const DATE_FORMAT = "MM-DD-YYYY h:mm A"
export const CURRENT_DATE_TIME = "YYYY-MM-DD HH:mm"

export const updateContactType = (contactType, setContactType, contactId) => {
  let newContactType = contactType === CONTACT_TYPE_HUMAN ? CONTACT_TYPE_COMPANY : CONTACT_TYPE_HUMAN
  return new Promise(async () => {
    await AxiosInstance.post("/contact/update-contact-type/" + contactId, {
      contactType: newContactType,
    }).then(function (response) {
      if (response.status === 200) {
        setContactType(newContactType)
      }
    })
  })
}

export const validatePhone = phone => {
  return phone.trim().length > 8 && phone.trim().length < 30
}
