import React from "react"
import AxiosInstance from "../../../../services/axiosInstance"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const GoogleTagsId = ({ googleTagsId, googleTagsValue, setGoogleTagsId, setGoogleTagsValue }) => {
  const handleSubmit = e => {
    if (googleTagsValue === e.target.value) {
      return false
    }

    var url = "/settings/general-setting/add"
    if (googleTagsId) {
      url = `/settings/general-setting/edit/${googleTagsId}`
    }

    AxiosInstance.post(url, {
      type: "GoogleTagsId",
      value: e.target.value,
      privacy: "public",
    })
      .then(response => {
        setGoogleTagsValue(e.target.value)
        setGoogleTagsId(response.data.id)
      })
      .catch(function (error) {})
  }

  return (
    <div className="row mb-3 align-items-center">
      <div className="col-md-4 setting_label">
        <label>Optional: Google Tags ID </label>{" "}
        <span>
          <OverlayTrigger placement="top" overlay={<Tooltip>i.e. GTM-XXXXXXX</Tooltip>}>
            <i className="bi bi-question-circle-fill"></i>
          </OverlayTrigger>
        </span>
      </div>
      <div className="col-md-8">
        <div className="input-group usernameInputContainer">
          <input
            type="text"
            className="form-control "
            placeholder="Google Tags Id"
            name="googleTagsId"
            defaultValue={googleTagsValue}
            onBlur={handleSubmit}
          />
        </div>
      </div>
    </div>
  )
}

export default GoogleTagsId
