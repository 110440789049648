import React, { useState } from "react"
import { Dropdown, Form, OverlayTrigger, Tooltip } from "react-bootstrap"

import {
  CUSTOM_ADDRESS,
  CUSTOM_NUMBER,
  CUSTOM_OPTIONS,
  CUSTOM_TEXTAREA,
  CUSTOM_TEXTBOX,
  CUSTOM_URL,
  NUMBER_RATTING,
  SLIDER_RATTING,
  STAR_RATTING,
} from "../../../Contacts/contactHelper"
import AxiosInstance from "../../../../services/axiosInstance"

const EditCustomContact = ({
  setCustomContactForm,
  customContactType,
  icons,
  setReloadData,
  fieldName,
  setFieldName,
  fieldType,
  setFieldType,
  fieldIcon,
  setFieldIcon,
}) => {
  const fieldTypeOptions = [
    { value: CUSTOM_TEXTBOX, label: "TextBox" },
    { value: CUSTOM_NUMBER, label: "TextBox (Number Only)" },
    { value: NUMBER_RATTING, label: "Ratings Box 0-10" },
    { value: STAR_RATTING, label: "Rating (1-5 Stars)" },
    { value: SLIDER_RATTING, label: "Slider 1-100" },
    { value: CUSTOM_TEXTAREA, label: "Text Area" },
    { value: CUSTOM_ADDRESS, label: "Address" },
    { value: CUSTOM_OPTIONS, label: "Dropdown" },
    { value: CUSTOM_URL, label: "Web URL" },
    { value: "disabled", label: "== Agreements / Subscriptions Ledgers ==" },
    { value: "txtboxNumberVisibleToContact", label: "TextBox (Number Only) (Shared Ledger)" },
    { value: "metered_value_rollover", label: "Metered Value (Roll-Over) (Shared Ledger)" },
    { value: "metered_value_reset_each_period", label: "Metered Value (Reset Each Period)" },
  ]

  const handleEdit = () => {
    if (!fieldName) {
      alert("Please add label.")
      return false
    }

    let url = `/settings/custom-field/add`
    if (customContactType) {
      url = `/settings/custom-field/edit/${customContactType.id}`
    }
    AxiosInstance.post(url, {
      label: fieldName,
      type: fieldType,
      icon: fieldIcon,
    })
      .then(response => {
        setReloadData(true)
        setFieldName("")
        setFieldIcon("")
        setFieldType("")
        setCustomContactForm(false)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const CustomMenu = React.forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("")
    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-75"
          placeholder="Search"
          onChange={e => setValue(e.target.value.toLowerCase())}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            child => !value || child.props.value.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    )
  })

  return (
    <div className="row">
      <div className="col-lg-6 col-md-8 mx-auto py-4" id="addNewCustomFieldContainer">
        <div className="row">
          <div className="col-lg-12 colHeading">
            <strong>
              <i className="bi bi-plus-lg" /> {`${customContactType ? "Edit" : "Add"}`} Custom Contact Field
            </strong>
          </div>
          <div className="col-lg-12 dh-block-body">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row mb-3 align-items-center">
                      <div className="col-sm-4">Field Name / Label</div>
                      <div className="col-sm-8">
                        <div className="input-group">
                          <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic" className="btn-default">
                              <i className={fieldIcon} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu as={CustomMenu} style={{ height: "250px", overflowY: "scroll" }}>
                              {icons.map(icon => {
                                return (
                                  <Dropdown.Item
                                    key={icon.icon}
                                    value={icon.icon}
                                    href="#"
                                    onClick={() => setFieldIcon(`bi bi-${icon.icon}`)}
                                  >
                                    <i className={`bi bi-${icon.icon}`} />
                                  </Dropdown.Item>
                                )
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                          <input
                            type="text"
                            name="newCustomField"
                            id="newCustomField"
                            className="form-control"
                            defaultValue={fieldName}
                            onChange={e => {
                              setFieldName(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3 align-items-center">
                      <div className="col-sm-4">
                        Field type
                        <span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                You can add these fields to intake forms, chatbot-intake-fields, etc.
                              </Tooltip>
                            }
                          >
                            <i className="bi bi-question-circle-fill ms-1" />
                          </OverlayTrigger>
                        </span>
                      </div>
                      <div className="col-sm-8">
                        <select
                          name="newCustomFieldOpts"
                          id="newCustomFieldOpts"
                          className="form-select"
                          value={fieldType}
                          key={fieldType}
                          onChange={e => {
                            setFieldType(e.target.value)
                          }}
                        >
                          {fieldTypeOptions.map((fields, index) => {
                            return (
                              <option
                                key={index}
                                value={fields.value}
                                disabled={fields.value === "disabled" ? "disabled" : ""}
                              >
                                {fields.label}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3 align-items-center fieldOptionsRow d-none">
                      <div className="col-sm-4">
                        Field Name / Label
                        <span>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Enter Comma Separatd Values</Tooltip>}
                          >
                            <i className="bi bi-question-circle-fill ms-1" />
                          </OverlayTrigger>
                        </span>
                      </div>
                      <div className="col-sm-8">
                        <input type="text" name="fieldOptions" id="fieldOptions" className="form-control" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 text-end">
                        <button
                          type="button"
                          name="btnCancel"
                          id="btnCancelAddNewCustomField"
                          className="btn btn-default"
                          onClick={() => setCustomContactForm(false)}
                        >
                          <i className="bi bi-x-lg" />
                          Cancel
                        </button>{" "}
                        <button
                          type="button"
                          name="btnSave"
                          id="btnSaveCustomField"
                          className="btn btn-primary"
                          onClick={handleEdit}
                        >
                          <i className="bi bi-check2" />
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditCustomContact
