import React, { useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

import AxiosInstance from "../../../../services/axiosInstance"
import TransactionTypeIconModel from "./transactionTypeIconModel"

const EditProject = ({
  setProjectForm,
  projectId,
  industryType,
  industryIcon,
  setIndustryType,
  setIndustryIcon,
  icons,
  setReloadData,
  showModal,
  setShowModal,
  iconColor,
  setIconColor,
  iconBgColor,
  setIconBgColor,
}) => {
  const handleEdit = () => {
    if (!industryType) {
      alert("Please add industry type.")
      return false
    }

    let url = `/settings/transaction-type/add`
    if (projectId) {
      url = `/settings/transaction-type/edit/${projectId.id}`
    }

    AxiosInstance.post(url, {
      dealTypeLabel: industryType,
      icon: industryIcon,
      iconColor: iconColor,
      iconBackgroundColor: iconBgColor,
    })
      .then(response => {
        setReloadData(true)
        setIndustryType("")
        setIndustryIcon("")
        setProjectForm(false)
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <div className="row">
      <div className="col-lg-6 col-md-8 mx-auto py-4" id="addNewTransactionTypesContainer">
        <div className="row">
          <div className="col-lg-12 colHeading">
            <strong>
              <i className="bi bi-person-fill-check" /> {`${projectId ? "Edit" : "Add"}`} Job / Project /
              Sales Opportunity Type
            </strong>
          </div>
          <div className="col-lg-12 dh-block-body">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row mb-3 align-items-center">
                      <div className="col-sm-4">
                        Job Type
                        <span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                What services do you sell? What pipelines of common project types you want to
                                automate?
                              </Tooltip>
                            }
                          >
                            <i className="bi bi-question-circle-fill ms-1" />
                          </OverlayTrigger>
                        </span>
                      </div>
                      <div className="col-sm-8">
                        <div className="input-group">
                          <button
                            type="button"
                            className={`btn btnPageTitle_Editor_Icon color-${iconColor}`}
                            style={{ backgroundColor: iconBgColor }}
                            onClick={() => {
                              setShowModal(true)
                            }}
                          >
                            <i className={`bi bi-${industryIcon}`} />
                          </button>

                          <input
                            type="text"
                            className="form-control"
                            name="asset_name"
                            id="asset_name"
                            value={industryType}
                            onChange={e => setIndustryType(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <TransactionTypeIconModel
                      showModal={showModal}
                      setShowModal={setShowModal}
                      iconColor={iconColor}
                      setIconColor={setIconColor}
                      iconBgColor={iconBgColor}
                      setIconBgColor={setIconBgColor}
                      industryIcon={industryIcon}
                      setIndustryIcon={setIndustryIcon}
                    />

                    <div className="row">
                      <div className="col-sm-12 text-end">
                        <button
                          type="button"
                          name="btnCancel"
                          id="btnCancelAddNewCustomField"
                          className="btn btn-default"
                          onClick={() => setProjectForm(false)}
                        >
                          <i className="bi bi-x-lg" />
                          Cancel
                        </button>{" "}
                        <button
                          type="button"
                          name="btnSave"
                          id="btnSaveCustomField"
                          className="btn btn-primary"
                          onClick={() => handleEdit()}
                        >
                          <i className="bi bi-check2" />
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditProject
