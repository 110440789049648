export const ASSET_TYPE_EXPENDABLE = "Expendable (Track Inventory)"
export const ASSET_TYPE_AGREEMENT = "Exclusive-Use (by Agreement)"
export const ASSET_TYPE_SCHEDULER = "Exclusive-Use (by Scheduler)"
export const ASSET_TYPE_NOT_APPLICABLE = "Not Applicable"

export const LOCATION_TYPE_FIXED = "Fixed"
export const LOCATION_TYPE_VARIABLE = "Variable"
export const LOCATION_TYPE_NOT_APPLICABLE = "Not Applicable"

export const ASSET_TYPES = [
  ASSET_TYPE_EXPENDABLE,
  ASSET_TYPE_AGREEMENT,
  ASSET_TYPE_SCHEDULER,
  ASSET_TYPE_NOT_APPLICABLE,
]

export const LOCATION_TYPES = [LOCATION_TYPE_FIXED, LOCATION_TYPE_VARIABLE, LOCATION_TYPE_NOT_APPLICABLE]
