import { Link } from "gatsby"
import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const EditReviews = ({ setEditReviews }) => {
  return (
    <div className="row">
      <div id="addNewReviewContainer" className="col-lg-6 col-md-8 mx-auto py-4">
        <div className="col-lg-12 colHeading">
          <strong>
            <i className="bi bi-plus-lg"></i> Edit Review Location
          </strong>
        </div>
        <div className="col-lg-12 dh-block-body">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-sm-12">
                  <div className="row mb-3 align-items-center">
                    <div className="col-sm-4">
                      <Link to="" target="_blank">
                        Review Location URL
                      </Link>
                      <span>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>i.e. Google Reviews, Yelp, Facebook, etc.</Tooltip>}
                        >
                          <i className="bi bi-question-circle-fill"></i>
                        </OverlayTrigger>
                      </span>
                    </div>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        name="review_location"
                        id="review_location"
                        className="form-control"
                        placeholder="https://"
                        value="https://"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 text-end">
                      <button
                        type="button"
                        name="btnCancel"
                        id="btnCancelAddNewCustomField"
                        className="btn btn-default"
                        onClick={() => setEditReviews(false)}
                      >
                        <i className="bi bi-x-lg"></i>
                        Cancel
                      </button>{" "}
                      <button
                        type="button"
                        name="btnSave"
                        id="btnSaveCustomField"
                        className="btn btn-primary"
                      >
                        <i className="bi bi-check2"></i>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditReviews
