import React, { useEffect, useState } from "react"
import AxiosInstance from "../../../../../services/axiosInstance"

const BranchRow = ({ companyData, industryData, serviceData }) => {
  const [company, setCompany] = useState("")
  const [companyId, setCompanyId] = useState(null)
  const [industry, setIndustry] = useState("")
  const [industryId, setIndustryId] = useState(null)
  const [service, setService] = useState("")
  const [serviceId, setServiceId] = useState(null)

  let serviceArea = [
    { value: "", label: "== Select ==" },
    { value: "Local-Address", label: "Local (From Address)" },
    { value: "Local-Service", label: "Local (Service Area)" },
    { value: "State", label: "State / Region" },
    { value: "Country", label: "Country" },
    { value: "Global", label: "Global / Anywhere" },
  ]

  //Company
  const handleCompanySubmit = e => {
    if (company === e.target.value) {
      return false
    }
    setCompany(e.target.value)
    let companyUrl = "/settings/general-setting/add"
    if (companyId) {
      companyUrl = `/settings/general-setting/edit/${companyId}`
    }
    AxiosInstance.post(companyUrl, {
      type: "CompanyName",
      value: e.target.value,
      privacy: "public",
    })
      .then(response => {
        setCompanyId(response.data.id)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (companyId == null && companyData) {
      setCompanyId(companyData.id)
      setCompany(companyData.value)
    }
  })

  //Industry
  const handleIndustrySubmit = e => {
    if (industry === e.target.value) {
      return false
    }
    setIndustry(e.target.value)
    let industryUrl = "/settings/general-setting/add"
    if (industryId) {
      industryUrl = `/settings/general-setting/edit/${industryId}`
    }
    AxiosInstance.post(industryUrl, {
      type: "IndustryName",
      value: e.target.value,
      privacy: "public",
    })
      .then(response => {
        setIndustryId(response.data.id)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (industryId == null && industryData) {
      setIndustryId(industryData.id)
      setIndustry(industryData.value)
    }
  })
  //Services

  const handleServiceSubmit = e => {
    if (service === e.target.value) {
      return false
    }
    setService(e.target.value)
    let ServiceUrl = "/settings/general-setting/add"
    if (serviceId) {
      ServiceUrl = `/settings/general-setting/edit/${serviceId}`
    }
    AxiosInstance.post(ServiceUrl, {
      type: "ServiceArea",
      value: e.target.value,
      privacy: "public",
    })
      .then(response => {
        console.log(response)
        setServiceId(response.data.id)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (serviceId == null && serviceData != null) {
      setServiceId(serviceData.id)
      setService(serviceData.value)
    }
  })

  return (
    <>
      <div className="row adbook-user-other-info align-items-center py-3 border-bottom company_info_row">
        <div className="col-md-4 text-md-end text-start">
          <label>Industry</label>
        </div>
        <div className="col-md-2 text-center privacy-col">
          <i className="bi bi-globe" />
        </div>
        <div className="col-md-4 inputbox">
          <input
            type="text"
            className="form-control"
            placeholder="Industry name"
            defaultValue={industry}
            onBlur={handleIndustrySubmit}
          />
        </div>
      </div>
      <div className="row adbook-user-other-info align-items-center py-3 border-bottom company_info_row">
        <div className="col-md-4 text-md-end text-start">
          <label>Service Area</label>
        </div>
        <div className="col-md-2 text-center privacy-col">
          <i className="bi bi-globe" />
        </div>
        <div className="col-md-4 inputbox">
          <select
            name="company_serve"
            id="company_serve"
            className="form-select"
            onChange={handleServiceSubmit}
            defaultValue={service}
            key={service}
          >
            {serviceArea.map(service => {
              return (
                <option value={service.value} key={service.value}>
                  {service.label}
                </option>
              )
            })}
          </select>
        </div>
      </div>
    </>
  )
}

export default BranchRow
