import React, { useState } from "react"
import { getUserName } from "../../../Drive/Doc/Service/docHelper"
import AxiosInstance from "../../../../services/axiosInstance"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Translate from "../../../translationHelper"

const UserNameRow = () => {
  const [errorMessage, setErrorMessage] = useState("")
  const [status, setStatus] = useState(false)

  const handleUserNameCheck = e => {
    if (e.target.value === "") {
      setStatus(false)
    }

    AxiosInstance.post(`/settings/general-setting/check-username`, {
      username: e.target.value,
    })
      .then(responce => {
        if (responce.status == 200) {
          setErrorMessage("Valid.")
          setStatus(true)
        }
      })
      .catch(error => {
        if (error.response.status == 400) {
          setStatus(false)
          setErrorMessage("Invalid.")
        }
      })
  }

  const handleUserNameEdit = e => {
    if (status === true) {
      if (e.target.value === "") {
        setStatus(false)
        setErrorMessage("Enter valid user name.")
        return false
      }
      if (e.target.value.length < 3) {
        setStatus(false)
        setErrorMessage("Write more then 3 letters")
        return false
      } else {
        let url = `/settings/general-setting/edit-username`
        AxiosInstance.post(url, {
          username: e.target.value,
        })
          .then(responce => {
            if (responce.status == 200) {
              setStatus(true)
              setErrorMessage("Username saved.")
              localStorage.setItem("userName", e.target.value)
            }
          })
          .catch(error => {
            if (error.response.status === 400) {
              setStatus(false)
              setErrorMessage("This value is already used.")
            }
          })
      }
    }
  }

  return (
    <div className="row adbook-user-other-info align-items-center py-3 border-top border-bottom">
      <div className="col-md-4 text-md-end text-start">
        <label>{Translate("Username")}</label>
      </div>
      <div className="col-md-2 text-center privacy-col">
        <i className="bi bi-globe" />
      </div>
      <div className="col-md-4 inputbox">
        <div className="input-group usernameInputContainer">
          <div className="input-group-text">@</div>
          <input
            type="text"
            className="form-control usernameInput "
            placeholder="Username"
            name="username"
            defaultValue={getUserName()}
            onBlur={handleUserNameEdit}
            onKeyUpCapture={handleUserNameCheck}
          />
          {errorMessage && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip className="ms-1 d-inline-block">{errorMessage}</Tooltip>}
            >
              <span className="validationErrorMsg ">
                {status === true && <i className="bi bi-check2 text-success"></i>}
                {status === false && <i className="bi bi-x-lg "></i>}
              </span>
            </OverlayTrigger>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserNameRow
