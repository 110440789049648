export const isBrowser = () => typeof window !== "undefined"

export const AUTOMATION_OPTION_EMAIL_ME = "emailMe"
export const AUTOMATION_OPTION_EMAIL_TO = "emailTo"
export const AUTOMATION_OPTION_EMAIL_START_SEQUENCE = "startSequence"
export const AUTOMATION_OPTION_EMAIL_START_SEQUENCE_BASED_ON_ROLE = "startSequenceBasedOnRole"
export const AUTOMATION_OPTION_EMAIL_ADD_CONTACT = "addContactToSwiftBook"
export const AUTOMATION_OPTION_EMAIL_SEND_SMS = "sendSMS"
export const AUTOMATION_OPTION_DISPLAY_IN_FEED = "displayInFeed"
export const AUTOMATION_OPTION_13 = "automation_13"
export const AUTOMATION_OPTION_14 = "automation_14"
export const AUTOMATION_OPTION_15 = "automation_15"
export const AUTOMATION_OPTION_CREATE_TASK_JOB = "create_task_job"
export const AUTOMATION_OPTION_START_PLAYBOOK = "start_playbook"
export const AUTOMATION_OPTION_WRITE_TO_SPREADSHEET = "write_to_spreadsheet"
export const AUTOMATION_OPTION_WRITE_TO_INVOICE = "write_to_invoice"
export const AUTOMATION_OPTION_23 = "automation_23"
export const AUTOMATION_OPTION_24 = "automation_24"
export const AUTOMATION_OPTION_25 = "automation_25"
export const AUTOMATION_OPTION_26 = "automation_26"
export const AUTOMATION_OPTION_27 = "automation_27"
export const AUTOMATION_OPTION_41 = "automation_41"
export const AUTOMATION_OPTION_42 = "automation_42"
export const AUTOMATION_OPTION_OTHER_API_URL = "other_api_url"
export const AUTOMATION_OPTION_51 = "automation_51"
export const AUTOMATION_OPTION_DISTRIBUTE_RULES = "distribute_rules"
export const AUTOMATION_OPTION_ADD_CONTACT_ToSWIFTBOOK = "addContactToSwiftBook"

export const setAdditionalOptionsArray = formData => {
  let ueDetails = []
  formData.forEach((value, key) => {
    if (key.startsWith("additional_options_")) {
      let postFix = key.substring(key.lastIndexOf("_"))
      let details = {}
      if (value === "getAnEmail") {
        details = {
          set_option: value,
          email_subject: formData.get("email_subject" + postFix),
          email_message: formData.get("email_message" + postFix),
          // email_message: ueContent,
          email_using: formData.get("email_using" + postFix),
          email_as: formData.get("email_as" + postFix),
        }
      } else if (value === "SMS") {
        details = {
          set_option: value,
          sms_message: formData.get("sms_message" + postFix),
          email_using: formData.get("email_using" + postFix),
        }
      } else {
        details = {
          set_option: value,
          marketing_sequence: formData.get("marketing_sequence" + postFix),
          email_using: formData.get("email_using" + postFix),
        }
      }
      ueDetails.push(details)
    }
  })
  return ueDetails
}

export const setAutomationOptionsArray = (formData, displayInFeedTempTags, contactTempTags) => {
  let DHDetails = []
  formData.forEach((value, key) => {
    if (key.startsWith("automation_option_")) {
      let postFix = key.substring(key.lastIndexOf("_"))
      let details = {}
      if (value === AUTOMATION_OPTION_EMAIL_ME) {
        details = {
          set_option: value,
        }
      } else if (value === AUTOMATION_OPTION_EMAIL_TO) {
        details = {
          set_option: value,
          email_to: formData.get("email_to" + postFix),
          email_format: formData.get("email_format" + postFix),
          template_name: formData.get("template_name" + postFix),
          digest_opt: formData.get("digest_opt" + postFix),
        }
      } else if (value === AUTOMATION_OPTION_EMAIL_START_SEQUENCE) {
        details = {
          set_option: value,
          marketing_sequence: formData.get("marketing_sequence" + postFix),
        }
      } else if (value === AUTOMATION_OPTION_EMAIL_ADD_CONTACT) {
        details = {
          set_option: value,
          // contact_tags: formData.get("contact_tags" + postFix),
          // conatct_feed_tag: contactTempTags,
          conatct_feed_tag: JSON.parse(formData.get("contact_tags" + postFix)),
          contact_option: formData.get("contactOpt" + postFix),
          contact_group: formData.get("contact_group_Opt" + postFix),
        }
      } else if (value === AUTOMATION_OPTION_DISPLAY_IN_FEED) {
        details = {
          set_option: value,
          diaply_in_feed_tag: displayInFeedTempTags,
        }
      } else if (value === AUTOMATION_OPTION_EMAIL_ADD_CONTACT) {
        details = {
          set_option: value,
        }
      } else if (value === AUTOMATION_OPTION_WRITE_TO_SPREADSHEET) {
        details = {
          set_option: value,
          spreadsheet: formData.get("write_to_spreadsheet_cls" + postFix),
          column: formData.get("extra_colname" + postFix),
          csv: formData.get("write_csv" + postFix),
          de_duplicate_email: formData.get("de_duplicate_by_email" + postFix),
        }
      } else if (value === AUTOMATION_OPTION_25) {
        details = {
          set_option: value,
          certificate: formData.get("certificate" + postFix),
        }
      } else if (value === AUTOMATION_OPTION_26) {
        details = {
          set_option: value,
          issue_point: formData.get("issue_point" + postFix),
        }
      } else if (value === AUTOMATION_OPTION_27) {
        details = {
          set_option: value,
          user_id: formData.get("user_id" + postFix),
          password: formData.get("password" + postFix),
        }
      }

      DHDetails.push(details)
    }
  })

  return DHDetails
}

export const getUserName = () => {
  return isBrowser() ? window.localStorage.getItem("userName") : ""
}

export const changeHypertextProtocol = str => {
  return str.replace("https://", "").replace("http://", "")
}
