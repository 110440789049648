import React, { useEffect, useState } from "react"
import AxiosInstance from "../../../../../services/axiosInstance"
import BranchRow from "./branchRow"
import Translate from "../../../../translationHelper"

const AccountForRow = ({ accountData, companyData, industryData, serviceData }) => {
  const [accountType, setAccountType] = useState(null),
    [accountId, setAccountId] = useState(null),
    [updateData, setUpdateData] = useState(true)

  const optionValue = [
    { value: "", label: "== Select ==" },
    { value: "Personal", label: "Personal account (Students or Job Seekers)" },
    { value: "Solopreneur", label: "Solopreneur Company Account" },
    { value: "Individual", label: "Individual Contributor (Linked to Company)" },
    { value: "Branch", label: "Branch / Division (Sub-Accounts + Data UpFlow)" },
    { value: "Company", label: "Company (with Sub-Accounts)" },
  ]

  const handleAccountType = e => {
    if (accountType === e.target.value) {
      return false
    }
    setAccountType(e.target.value)
    let url = "/settings/general-setting/add"
    if (accountId) {
      url = `/settings/general-setting/edit/${accountId}`
    }
    AxiosInstance.post(url, {
      type: "AccountType",
      value: e.target.value,
      privacy: "public",
    })
      .then(response => {
        setAccountId(response.data.id)
      })
      .catch(function () {})
  }

  useEffect(() => {
    if (accountId === null && accountData != null) {
      setUpdateData(false)
      setAccountId(accountData.id)
      setAccountType(accountData.value)
    }
  })

  return (
    <>
      <div className="row adbook-user-other-info align-items-center py-3 border-bottom">
        <div className="col-md-4 text-md-end text-start">
          <label>{Translate("This account is for a...")}</label>
        </div>
        <div className="col-md-2 text-center privacy-col">
          <i className="bi bi-eye-slash-fill" />
        </div>
        <div className="col-md-4 inputbox">
          <select
            name="userAccountType"
            id="userAccountType"
            className="form-select"
            onChange={handleAccountType}
            defaultValue={accountType}
            key={accountType}
          >
            {optionValue.map(({ value, label }, index) => {
              return (
                <option value={value} key={index}>
                  {label}
                </option>
              )
            })}
          </select>

          {accountType === "Branch" && (
            <div className="company_info_row mt-3">
              <a href="#" className="linkManageStaff">
                Manage Staff
              </a>
            </div>
          )}
          {["Individual", "Branch", "Company"].indexOf(accountType) > -1 && (
            <div className={`personal_info_row connect_to_company_row mt-3`}>
              Optional: Connect to Company Account{" "}
              <a href="#" className="btnConnectToCompany">
                <i className="bi bi-plus-lg" />
              </a>
            </div>
          )}
        </div>
      </div>

      {accountType === "Branch" && (
        <BranchRow companyData={companyData} industryData={industryData} serviceData={serviceData} />
      )}
    </>
  )
}

export default AccountForRow
