import React, { useEffect } from "react"

const EditTagField = ({
  addTag,
  setAddTag,
  newTag,
  setNewTag,
  setTempTagsEdit,
  tempTagsEdit,
  removeTagIndex,
  setRemoveTagIndex,
}) => {
  useEffect(() => {
    if (addTag) {
      setAddTag(false)
      let filterTag = tempTagsEdit.filter(tags => {
        return tags.tag === newTag
      })
      if (filterTag.length === 0) {
        setTempTagsEdit([
          ...tempTagsEdit,
          {
            tag: newTag,
            score: 0,
            color: "blue",
          },
        ])
        setNewTag("")
      }
    }
    if (removeTagIndex) {
      setRemoveTagIndex(null)
      let tempTag = []

      tempTagsEdit.map((tagArray, arrayIndex) => {
        if (parseInt(removeTagIndex) !== arrayIndex) {
          tempTag = [...tempTag, tagArray]
        }
        if (arrayIndex === tempTagsEdit.length - 1) {
          setTempTagsEdit([...tempTag])
        }
      })
    }
  })
  return (
    <div className="row mb-3 align-items-center">
      <div className="col-sm-4">Default Role Tags</div>
      <div className="col-sm-8">
        <div className="bootstrap-tagsinput">
          {tempTagsEdit.length > 0 &&
            tempTagsEdit.map((tagDetail, tagIndex) => (
              <span className="tag label label-info" key={tagIndex}>
                {tagDetail.tag}
                <span data-role="remove" onClick={() => setRemoveTagIndex(`${tagIndex}`)} />
                <span data-role="drag" />
              </span>
            ))}
          <input
            type="text"
            placeholder="Enter a #tag then click Tab"
            size="27"
            value={newTag}
            onKeyDown={e => {
              if (e.code === "Tab") {
                setAddTag(true)
              }
            }}
            onChange={e => {
              setNewTag(e.target.value)
            }}
          />
          <button className="btn btn-sm btn-primary btnSaveTag">
            <i className="fa fa-save"></i>
          </button>
        </div>
      </div>
    </div>
  )
}

export default EditTagField
