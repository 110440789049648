import AxiosInstance from "../../../services/axiosInstance"
import { getLoginUserId } from "../../../services/auth"

export const save = ({ id, type, value, setValue, setId }) => {
  setValue(value)

  let url = "/settings/general-setting/add"
  if (id) {
    url = `/settings/general-setting/edit/${id}`
  }

  AxiosInstance.post(url, {
    type: type,
    value: value,
    privacy: "public",
  })
    .then(response => {
      setId(response.data.id)
    })
    .catch(function (error) {})
}

export const uploadFileR2 = ({ file, setValue, id, type, setId }) => {
  return new Promise(async () => {
    let formData = new FormData()
    formData.append("ProfilePic", file)
    formData.append("user_id", getLoginUserId())
    formData.append("parent_id", "0")
    await AxiosInstance.post(`/settings/general-setting/upload-file-r2`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.status === 201) {
          save({ id, type, value: response.data.profilePic.filePath, setValue, setId })
        }
      })
      .catch(function (error) {})
  })
}

export const deleteFile = ({ id, setValue, setId }) => {
  AxiosInstance.delete(`/settings/general-setting/remove-profile-pic/${id}`)
    .then(function (response) {
      if (response.status === 200) {
        setValue("")
        setId(null)
      }
    })
    .catch(function (error) {})
}
