import React, { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import AxiosInstance from "../../../services/axiosInstance"
import Privacy from "../privacy"

const UrlMappedRow = ({ settingData }) => {
  const [connectionIcon, setConnectionIcon] = useState("bi bi-eye-slash-fill")
  const [connectionType, setConnectionType] = useState(settingData?.privacy)
  const [value, setValue] = useState(settingData?.value)
  const [id, setId] = useState(settingData?.id)
  const [isLoading, setIsLoading] = useState(false)

  const CONNECTION_OPTIONS = [
    {
      lable: "Hidden / Secret",
      type: "hidden",
      icon: "bi bi-eye-slash-fill",
    },
    {
      lable: "Connections only",
      type: "private",
      icon: "bi bi-people-fill",
    },
    {
      lable: "Public",
      type: "public",
      icon: "bi bi-globe",
    },
  ]

  const handlePrimaryRow = e => {
    if (value === e.target.value) {
      return false
    }

    setValue(e.target.value)

    let url = "/settings/general-setting/add"
    if (id) {
      url = `/settings/general-setting/edit/${id}`
    }
    AxiosInstance.post(url, {
      type: "URLMappedTo",
      value: e.target.value,
      privacy: connectionType,
    })
      .then(response => {
        setId(response.data.id)
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          console.log(error)
        }
      })
  }

  const handleConnection = (icon, type) => {
    setConnectionIcon(icon)
    setConnectionType(type)
    setIsLoading(true)
  }

  useEffect(() => {
    if (isLoading) {
      handlePrimaryRow()
    }

    const connection = CONNECTION_OPTIONS.filter(data => {
      return data.type === connectionType
    })

    if (connection.length > 0) {
      setConnectionIcon(connection[0].icon)
    }
  }, [connectionType])

  return (
    <div className="row adbook-user-other-info align-items-center py-3 border-bottom">
      <div className="col-md-4 text-md-end text-start">
        <label>
          <i className="bi bi-link-45deg" /> URL Mapped to SwiftCloud
        </label>
      </div>
      <div className="col-md-2 text-center privacy-col">
        <div className="dropdown adbook-user-data-privacy">
          <i className="bi bi-globe" />
          {/* <Dropdown>
            <Dropdown.Toggle variant="" id="dropdown-basic">
              <i className={connectionIcon} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {CONNECTION_OPTIONS.map(connection => {
                return (
                  <Dropdown.Item
                    key={connection.type}
                    value={connection.type}
                    href="#"
                    onClick={() => handleConnection(connection.icon, connection.type)}
                  >
                    <i className={connection.icon} /> {connection.lable}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </div>
      <div className="col-md-4 inputbox">
        <input
          className="form-control"
          type="url"
          placeholder="https://"
          required="required"
          defaultValue={value}
          onBlur={handlePrimaryRow}
        />
      </div>
    </div>
  )
}

export default UrlMappedRow
