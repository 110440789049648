import React, { useEffect, useState } from "react"
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap"
import AxiosInstance from "../../../../services/axiosInstance"
import AddRaveScore from "./addRaveScore"
import EditRaveScore from "./editRaveScore"

const Roles = () => {
  const [roleForm, setRoleForm] = useState(false)
  const [editForm, setEditForm] = useState(false)
  const [table, setTable] = useState(null)
  const [tableload, setTableLoad] = useState(false)
  const [filterRoleTableId, setFilterRoleTableID] = useState(null)
  const [tempTagsEdit, setTempTagsEdit] = useState([])
  const [assignBlock, setAssignBlock] = useState("")
  const [tagColor, setTagColor] = useState("")
  const [assignTagsId, setAssignTagsId] = useState([])
  const [assignTag, setAssignTag] = useState([])
  const [assignTags, setAssignTags] = useState([])
  const [role, setRole] = useState("")

  const handleAddForm = () => {
    setRoleForm(true)
    setEditForm(false)
  }
  const businessRoleTable = () => {
    AxiosInstance.get("/settings/role")
      .then(response => {
        if (response.data.items.length === 0) {
          setTable(null)
        } else {
          setTable(response.data.items)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    businessRoleTable()
  }, [])

  useEffect(() => {
    if (tableload == true) {
      businessRoleTable()
      setTableLoad(false)
    }
  })

  const handleDelete = Id => {
    AxiosInstance.delete(`/settings/role/remove/${Id}`)
      .then(response => {
        if (response.status == 200) {
          businessRoleTable()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleEdit = id => {
    setEditForm(true)
    setRoleForm(false)
    let filterData = table.filter(tableData => {
      return tableData.id == id
    })
    setTempTagsEdit(filterData[0].tags)
    setFilterRoleTableID(filterData)
    setRole(filterData[0].role)
  }

  const handleDefault = Id => {
    AxiosInstance.post(`/settings/role/make-default/${Id}`)
      .then(response => {
        if (response.status === 200) {
          businessRoleTable()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleAssignBlock = tagName => {
    setAssignBlock(tagName)
  }

  const handleTagColor = (tagType, tag, tags, tagsId) => {
    setAssignTags(tags)
    setAssignTag([tag])
    let filterTable = table.filter(tableData => {
      return tableData.id == tagsId
    })
    setAssignTagsId(filterTable)
    let type = tagType
    switch (type) {
      case "blue":
        setTagColor("blue")
        break
      case "pink":
        setTagColor("pink")
        break
      case "beige":
        setTagColor("beige")
        break
      case "green":
        setTagColor("green")
        break
      case "gray":
        setTagColor("gray")
        break
      default:
        setTagColor("blue")
    }
  }
  useEffect(() => {
    if (assignTag.length > 0) {
      assignTag[0].color = tagColor
    }
    assignTag.forEach(elements => {
      const tagNames = assignTags.findIndex(tags => tags.tag === elements.tag)
      if (tagNames > -1) {
        assignTags[tagNames] = elements
      } else {
        assignTags = assignTags.push(elements)
      }
    })
    if (assignTag.length > 0) {
      AxiosInstance.post(`/settings/role/edit/${assignTagsId[0].id}`, {
        role: assignTagsId[0].role,
        tags: assignTags,
      })
        .then(response => {
          console.log(response)
          setTableLoad(true)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [tagColor])
  return (
    <Accordion.Item eventKey="1">
      <Accordion.Header>
        <i className="bi bi-person-fill me-2" />
        Roles
        <span>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>"What roles apply to your business? Client, patient, vendor, etc."</Tooltip>}
          >
            <i className="bi bi-question-circle-fill ms-1"></i>
          </OverlayTrigger>
        </span>
      </Accordion.Header>
      <Accordion.Body>
        {roleForm == true && (
          <AddRaveScore
            roleForm={roleForm}
            setRoleForm={setRoleForm}
            setTableLoad={setTableLoad}
            filterRoleTableId={filterRoleTableId}
            setFilterRoleTableID={setFilterRoleTableID}
          />
        )}
        {editForm == true && (
          <EditRaveScore
            editForm={editForm}
            setEditForm={setEditForm}
            setTableLoad={setTableLoad}
            filterRoleTableId={filterRoleTableId}
            setFilterRoleTableID={setFilterRoleTableID}
            tempTagsEdit={tempTagsEdit}
            setTempTagsEdit={setTempTagsEdit}
            setTagColor={setTagColor}
            tagColor={tagColor}
            role={role}
            setRole={setRole}
          />
        )}

        <div className="row p-3">
          <div className="col-lg-10 mx-auto">
            <div className="row mb-2 align-items-center">
              <div className="col-md-6">
                <h4 className="mb-0">
                  <i className="bi bi-person-fill" /> Roles for Your Business
                </h4>
              </div>
              <div className="col-md-6 text-end">
                <button className="btn btn-primary btnAddNewRolemain" onClick={handleAddForm}>
                  <i className="bi bi-plus-lg"></i>
                  Add New
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table id="rolesForBusiness" className="table table-striped dataTable no-footer" width="100%">
                <thead>
                  <tr>
                    <th width="40%">Role</th>
                    <th width="40%">Default Tags</th>
                    <th width="20%"></th>
                  </tr>
                </thead>
                <tbody>
                  {table === null ? (
                    <tr>
                      <td colSpan={3} className="text-center">
                        No data found.
                      </td>
                    </tr>
                  ) : (
                    table.map(tableData => {
                      return (
                        <tr key={tableData.id}>
                          <td>{tableData.role}</td>
                          <td>
                            <div className="SwiftCloudTableTags">
                              <ul>
                                {tableData.tags.map((tags, tagIndex) => {
                                  return (
                                    <li
                                      key={tagIndex}
                                      // className={assignBlock==tags.tag?tagColor:`tag-${tags.color}`}
                                      className={
                                        assignBlock == tags ? `tag-${tagColor}` : `tag-${tags.color}`
                                      }
                                      onMouseEnter={() => handleAssignBlock(tags)}
                                      onMouseLeave={() => setAssignBlock("")}
                                    >
                                      <a href="#">{tags.tag}</a>
                                      {assignBlock == tags && (
                                        <div className="SwiftCloudTagScore">
                                          <div className="mb-2 SwiftCloudTagScoreType">
                                            <a
                                              className="SwiftCloudTagScoreBlue"
                                              onClick={() => {
                                                handleTagColor("blue", tags, tableData.tags, tableData.id)
                                              }}
                                              data-type="blue"
                                            ></a>
                                            <a
                                              className="SwiftCloudTagScorePink"
                                              onClick={() => {
                                                handleTagColor("pink", tags, tableData.tags, tableData.id)
                                              }}
                                              data-type="pink"
                                            ></a>
                                            <a
                                              className="SwiftCloudTagScoreBeige"
                                              onClick={() =>
                                                handleTagColor("beige", tags, tableData.tags, tableData.id)
                                              }
                                              data-type="beige"
                                            ></a>
                                            <a
                                              className="SwiftCloudTagScoreGreen"
                                              onClick={() =>
                                                handleTagColor("green", tags, tableData.tags, tableData.id)
                                              }
                                              data-type="green"
                                            ></a>
                                            <a
                                              className="SwiftCloudTagScoreGray"
                                              onClick={() =>
                                                handleTagColor("gray", tags, tableData.tags, tableData.id)
                                              }
                                              data-type="gray"
                                            ></a>
                                          </div>
                                          <span>Assign Tag Score / Value</span>
                                          <div className="input-group">
                                            <input type="number" className="form-control tagScoreInput" />
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-success btnSaveTagScore"
                                            >
                                              <i className="bi bi-check2"></i>
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          </td>

                          <td className={`text-end tableColAction d-none d-md-table-cell`}>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Default</Tooltip>}>
                              <a
                                className={`btn btn-sm ${
                                  tableData.isDefault ? "btnTableRowDefault" : "btnSetAsDefault"
                                } `}
                                onClick={() => {
                                  handleDefault(tableData.id)
                                }}
                              >
                                <i className="bi bi-check2" />
                              </a>
                            </OverlayTrigger>{" "}
                            <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                              <a className="btn btn-edit" onClick={() => handleEdit(tableData.id)}>
                                <i className="bi bi-pencil-fill"></i>
                              </a>
                            </OverlayTrigger>{" "}
                            <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                              <a className="btn btn-delete" onClick={() => handleDelete(tableData.id)}>
                                <i className="bi bi-trash-fill"></i>
                              </a>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      )
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default Roles
