import React from "react"

const ReviewsSetting = ({ setSetting }) => {
  return (
    <div className="row">
      <div className="col-lg-6 col-md-8 mx-auto py-4" id="addNewReviewSettingsContainer">
        <div className="row">
          <div className="col-lg-12 colHeading">
            <strong>
              <i className="bi bi-plus-lg"></i> Reviews & Refer-a-Friend Settings
            </strong>
          </div>
          <div className="col-lg-12 dh-block-body">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row mb-3 align-items-center">
                      <div className="col-sm-4">Form to Review Your Business</div>
                      <div className="col-sm-8">
                        <select name="refer_a_friend_form" id="refer_a_friend_form" className="form-select">
                          <option value="FormList">== Form List ==</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3 align-items-center">
                      <div className="col-sm-4">Refer a Friend Form</div>
                      <div className="col-sm-8">
                        <select name="review_page" id="review_page" className="form-select">
                          <option value="WebPageList">== Web page List ==</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 text-end">
                        <button
                          type="button"
                          name="btnCancel"
                          id="btnCancelAddNewCustomField"
                          className="btn btn-default"
                          onClick={() => setSetting(false)}
                        >
                          <i className="bi bi-x-lg"></i>
                          Cancel
                        </button>{" "}
                        <button
                          type="button"
                          name="btnSave"
                          id="btnSaveCustomField"
                          className="btn btn-primary"
                        >
                          <i className="bi bi-check2"></i>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewsSetting
