import React, { useEffect, useState } from "react"
import CustomBackground from "./customBackground"
import Font from "./font"
import LightBackgroundColor from "./lightBackgroundColor"
import DarkBackgroundColor from "./darkBackgroundColor"
import AccentLightColor from "./accentLightColor"
import AccentDarkColor from "./accentDarkColor"
import LightBackgroundLogo from "./lightBackgroundLogo"
import DarkBackgroundLogo from "./darkBackgroundLogo"
import Favicon from "./favicon"
import GoogleTagsId from "../../ProfilePage/UniqRows/googleTagsId"
import AxiosInstance from "../../../../services/axiosInstance"

const BrandingSetting = ({
  customBackground,
  font,
  lightBackgroundColor,
  darkBackgroundColor,
  accentLightColor,
  accentDarkColor,
  lightBackgroundLogo,
  darkBackgroundLogo,
  favicon,
}) => {
  const [googleTagsId, setGoogleTagsId] = useState(null),
    [googleTagsValue, setGoogleTagsValue] = useState(null)

  const getCompanyDetails = async () => {
    AxiosInstance.get("/settings/general-setting/unique-data").then(response => {
      response.data.uniqueData.map(data => {
        if (data.type === "GoogleTagsId") {
          setGoogleTagsId(data.id)
          setGoogleTagsValue(data.value)
        }
      })
    })
  }

  useEffect(() => {
    getCompanyDetails()
  }, [])

  return (
    <>
      <LightBackgroundLogo data={lightBackgroundLogo} lightBackgroundColor={lightBackgroundColor} />
      <DarkBackgroundLogo data={darkBackgroundLogo} darkBackgroundColor={darkBackgroundColor} />
      <Favicon data={favicon} />
      <CustomBackground data={customBackground} />
      <LightBackgroundColor data={lightBackgroundColor} />
      <DarkBackgroundColor data={darkBackgroundColor} />
      <AccentLightColor data={accentLightColor} />
      <AccentDarkColor data={accentDarkColor} />

      <div className="row mb-3 align-items-center align-items-center company_background_image_container d-none">
        <div className="col-md-4 setting_label">
          <label htmlFor="background_image" className="col-form-label">
            Background Image (Optional)
          </label>
        </div>
        <div className="col-md-8">
          <div className="company_logo_container">
            <img src="images/no-image.jpg" className="favicon" />
            <button type="button" className="btn btnEditCompanyLogo">
              <i className="bi bi-pencil-fill" />
            </button>
            <button type="button" className="btn btnDeleteCompanyLogo">
              <i className="bi bi-x-lg" />
            </button>
          </div>
        </div>
      </div>
      <div className="row mb-3 align-items-center company_background_image_container d-none">
        <div className="col-md-4 setting_label">
          <label htmlFor="background_image_repeat" className="col-form-label">
            Background Image Repeat
          </label>
        </div>
        <div className="col-md-8">
          <select id="background_image_repeat" name="background_image_repeat" className="form-select">
            <option value="repeat-x">Repeat X</option>
            <option value="repeat-y">Repeat Y</option>
            <option value="repeat">Tile X&amp;Y</option>
            <option value="no-repeat">None</option>
          </select>
        </div>
      </div>

      <Font data={font} />
      <GoogleTagsId
        googleTagsId={googleTagsId}
        googleTagsValue={googleTagsValue}
        setGoogleTagsId={setGoogleTagsId}
        setGoogleTagsValue={setGoogleTagsValue}
      />
    </>
  )
}

export default BrandingSetting
