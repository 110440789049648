import * as React from "react"
import { useState, useEffect } from "react"
import { Dropdown, Modal, Form } from "react-bootstrap"
import axios from "axios"

const TransactionTypeIconModel = ({
  showModal,
  setShowModal,
  iconColor,
  setIconColor,
  iconBgColor,
  setIconBgColor,
  industryIcon,
  setIndustryIcon,
}) => {
  const [icons, setIcons] = useState([])

  const getIcons = () => {
    const unInterceptedAxiosInstance = axios.create()
    unInterceptedAxiosInstance.get(`${process.env.GATSBY_API_URL}/icon/list`).then(response => {
      setIcons(response.data.data)
    })
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const formData = new FormData(e.target)

    let transactionIconColor = formData.get("icon_font_color")
      ? formData.get("icon_font_color").toLowerCase()
      : iconColor
    let transactionIconBgColor = formData.get("background_color")
      ? formData.get("background_color")
      : iconBgColor

    setIconColor(transactionIconColor)
    setIconBgColor(transactionIconBgColor)

    handleClose()
  }

  useEffect(() => {
    getIcons()
  }, [])

  const CustomMenu = React.forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("")
    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-75"
          placeholder="Search"
          onChange={e => setValue(e.target.value.toLowerCase())}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            child => !value || child.props.value.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    )
  })

  return (
    <Modal show={showModal} onHide={handleClose} id="setTransactionTypeIconModel">
      <form onSubmit={handleSubmit} method="post">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="setTransactionTypeIconModelTitle">
              <i className="bi bi-gear-fill" /> Settings
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            />
          </div>
          <div className="modal-body">
            <div className="mb-3 row align-items-center">
              <div className="col-lg-4">Icon</div>
              <div className="col-lg-8">
                <div className="dropdown d-inline-block me-3">
                  <div className="input-group">
                    <Dropdown>
                      <Dropdown.Toggle variant="" id="dropdown-basic" className="btn-default">
                        <i className={`bi bi-${industryIcon}`} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu as={CustomMenu} style={{ height: "250px", overflowY: "scroll" }}>
                        {icons.map(icon => {
                          return (
                            <Dropdown.Item
                              key={icon.icon}
                              value={icon.icon}
                              href="#"
                              onClick={() => setIndustryIcon(icon.icon)}
                            >
                              <i className={`bi bi-${icon.icon}`} />
                            </Dropdown.Item>
                          )
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>

                <input
                  type="radio"
                  name="icon_font_color"
                  id="icon_font_color_white"
                  defaultValue="White"
                  className="css-checkbox"
                  checked={iconColor === "white"}
                  onChange={() => setIconColor("white")}
                />
                <label htmlFor="icon_font_color_white" className="css-radio-label me-3">
                  White
                </label>
                <input
                  type="radio"
                  name="icon_font_color"
                  id="icon_font_color_black"
                  defaultValue="Black"
                  className="css-checkbox"
                  checked={iconColor !== "white"}
                  onChange={() => setIconColor("black")}
                />
                <label htmlFor="icon_font_color_black" className="css-radio-label">
                  Black
                </label>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4">Background</div>
              <div className="col-lg-8">
                <input
                  type="color"
                  className="form-control form-control-color"
                  id="background_color"
                  name="background_color"
                  defaultValue={iconBgColor}
                  title="Choose your color"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default" data-bs-dismiss="modal" onClick={handleClose}>
              <i className="bi bi-x-lg" /> Close
            </button>
            <button type="submit" className="btn btn-primary">
              <i className="bi bi-check2" /> Save
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default TransactionTypeIconModel
