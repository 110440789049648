import React, { useEffect, useState } from "react"
import { save } from "../helper"

const DarkBackgroundColor = ({ data }) => {
  const [value, setValue] = useState("#383838"),
    [id, setId] = useState(null),
    [tempColor, setTempColor] = useState("")

  const handleChange = event => {
    setTempColor(event.target.value)
  }

  const handleSubmit = e => {
    if (value !== e.target.value) {
      setValue(e.target.value)
      save({ id, type: "BrandingDarkBgColor", value: e.target.value, setValue, setId })
    }
  }

  useEffect(() => {
    if (id === null && data) {
      setId(data.id)
      setValue(data.value)
    }
  })

  return (
    <div className="row mb-3 align-items-center company_background_color_dark_container">
      <div className="col-md-4 setting_label">
        <label htmlFor="background_color_dark" className="col-form-label">
          Dark Background Color
        </label>
      </div>
      <div className="col-md-8">
        <input
          type="color"
          className="form-control form-control-color"
          id="company_background_color_dark"
          name="company_background_color_dark"
          value={tempColor === "" ? value : tempColor}
          title="Choose your color"
          onChange={handleChange}
          onMouseLeave={handleSubmit}
        />
      </div>
    </div>
  )
}

export default DarkBackgroundColor
