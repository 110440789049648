import React, { useEffect, useState } from "react"

import AxiosInstance from "../../../../services/axiosInstance"

const Tags = ({ type, tags, assetTypes, assetType, setAssetType }) => {
  const [assignBlock, setAssignBlock] = useState("")
  const [tagColor, setTagColor] = useState("")
  const [assignTag, setAssignTag] = useState([])
  const [assignTags, setAssignTags] = useState([])

  const handleAssignBlock = tagName => {
    setAssignBlock(tagName)
  }

  const handleTagColor = (tagType, tag, tags, tagsId) => {
    setAssignTags(tags)
    setAssignTag([tag])
    let filterTable = assetTypes.filter(type => {
      return type.id === tagsId
    })
    setAssetType(filterTable[0])
    switch (tagType) {
      case "blue":
        setTagColor("blue")
        break
      case "pink":
        setTagColor("pink")
        break
      case "beige":
        setTagColor("beige")
        break
      case "green":
        setTagColor("green")
        break
      case "gray":
        setTagColor("gray")
        break
      default:
        setTagColor("blue")
    }
  }

  useEffect(() => {
    if (assignTag.length > 0) {
      assignTag[0].color = tagColor
    }
    assignTag.forEach(elements => {
      const tagNames = assignTags.findIndex(tags => tags.tag === elements.tag)
      if (tagNames > -1) {
        assignTags[tagNames] = elements
      } else {
        assignTags = assignTags.push(elements)
      }
    })
    if (assignTag.length > 0) {
      AxiosInstance.post(`/settings/asset-type/edit/${assetType.id}`, {
        name: assetType.name,
        icon: assetType.icon,
        assetType: assetType.assetType,
        locationType: assetType.locationType,
        tags: assignTags,
      })
        .then()
        .catch(error => {
          console.log(error)
        })
    }
  }, [tagColor])

  return (
    <div className="SwiftCloudTableTags">
      <ul>
        {tags.map((tag, tagIndex) => {
          return (
            <li
              key={tagIndex}
              className={assignBlock === tag ? `tag-${tagColor}` : `tag-${tag.color}`}
              onMouseEnter={() => handleAssignBlock(tag)}
              onMouseLeave={() => setAssignBlock("")}
            >
              <a href="#">{tag.tag}</a>
              {assignBlock === tag && (
                <div className="SwiftCloudTagScore">
                  <div className="mb-2 SwiftCloudTagScoreType">
                    <a
                      className="SwiftCloudTagScoreBlue"
                      onClick={() => {
                        handleTagColor("blue", tag, type.tags, type.id)
                      }}
                      data-type="blue"
                    />
                    <a
                      className="SwiftCloudTagScorePink"
                      onClick={() => {
                        handleTagColor("pink", tag, type.tags, type.id)
                      }}
                      data-type="pink"
                    />
                    <a
                      className="SwiftCloudTagScoreBeige"
                      onClick={() => handleTagColor("beige", tag, type.tags, type.id)}
                      data-type="beige"
                    />
                    <a
                      className="SwiftCloudTagScoreGreen"
                      onClick={() => handleTagColor("green", tag, type.tags, type.id)}
                      data-type="green"
                    />
                    <a
                      className="SwiftCloudTagScoreGray"
                      onClick={() => handleTagColor("gray", tag, type.tags, type.id)}
                      data-type="gray"
                    />
                  </div>
                  <span>Assign Tag Score / Value</span>
                  <div className="input-group">
                    <input type="number" className="form-control tagScoreInput" />
                    <button type="button" className="btn btn-sm btn-success btnSaveTagScore">
                      <i className="bi bi-check2" />
                    </button>
                  </div>
                </div>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Tags
