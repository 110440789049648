import React, { useEffect, useRef, useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

import { deleteFile, uploadFileR2 } from "../helper"

const Favicon = ({ data }) => {
  const [value, setValue] = useState(""),
    [id, setId] = useState(null),
    [file, setFile] = useState(null),
    [uploadFile, setUploadFile] = useState(false)

  const onLogoChange = e => {
    setFile(e.target.files[0])
    setUploadFile(true)
  }

  const logo = useRef(null)
  const openSelectLogoBox = () => {
    logo.current.click()
  }

  useEffect(() => {
    if (id === null && data) {
      setId(data.id)
      setValue(data.value)
    }

    if (uploadFile) {
      setUploadFile(false)
      uploadFileR2({ file, setValue: setValue, id, type: "BrandingFavicon", setId })
    }
  })

  return (
    <div className="row mb-3 align-items-center">
      <div className="col-md-4 setting_label">
        <label htmlFor="favicon" className="col-form-label">
          Favicon{" "}
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="ms-1 d-inline-block">
                Recommended: 32px wide by 32px high - jpg, png, ico or svg
              </Tooltip>
            }
          >
            <i className="bi bi-question-circle-fill" />
          </OverlayTrigger>
        </label>
      </div>
      <div className="col-md-8">
        <div className="favicon_container">
          <img
            src={`${process.env.GATSBY_WRANGLER_URL}${value ? value : "StaticImages/favicon.png"}`}
            className="favicon"
            alt=""
          />
          <input type="file" ref={logo} onChange={onLogoChange} hidden />
          <button type="button" className="btn btnEditCompanyLogo" onClick={openSelectLogoBox}>
            <i className="bi bi-pencil-fill" />
          </button>
          {id && (
            <button
              type="button"
              className="btn btnDeleteCompanyLogo"
              onClick={() => deleteFile({ id, setId, setValue })}
            >
              <i className="bi bi-x-lg" />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Favicon
