import React, { useEffect, useState } from "react"
import { Accordion } from "react-bootstrap"

import AxiosInstance from "../../../../services/axiosInstance"
import BrandingSetting from "./brandingSetting"
import { save } from "../helper"

const Branding = () => {
  const [brandingSettingValue, setBrandingSettingValue] = useState("On"),
    [brandingSettingId, setBrandingSettingId] = useState(null),
    [lightBackgroundLogo, setLightBackgroundLogo] = useState(""),
    [darkBackgroundLogo, setDarkBackgroundLogo] = useState(""),
    [customBackground, setCustomBackground] = useState(""),
    [lightBackgroundColor, setLightBackgroundColor] = useState(""),
    [darkBackgroundColor, setDarkBackgroundColor] = useState(""),
    [accentLightColor, setAccentLightColor] = useState(""),
    [accentDarkColor, setAccentDarkColor] = useState(""),
    [favicon, setFavicon] = useState(""),
    [font, setFont] = useState("")

  const handleSubmit = e => {
    save({
      id: brandingSettingId,
      type: "BrandingSetting",
      value: e.target.checked ? "On" : "Off",
      setValue: setBrandingSettingValue,
      setId: setBrandingSettingId,
    })
  }

  const setBrandingDetails = async () => {
    AxiosInstance.get("/settings/general-setting/branding-data").then(response => {
      response.data.data.map(data => {
        if (data.type === "BrandingSetting") {
          setBrandingSettingValue(data.value)
          setBrandingSettingId(data.id)
        }
        if (data.type === "BrandingLightBgLogo") {
          setLightBackgroundLogo(data)
        }
        if (data.type === "BrandingDarkBgLogo") {
          setDarkBackgroundLogo(data)
        }
        if (data.type === "BrandingFavicon") {
          setFavicon(data)
        }
        if (data.type === "BrandingCustomBg") {
          setCustomBackground(data)
        }
        if (data.type === "BrandingLightBgColor") {
          setLightBackgroundColor(data)
        }
        if (data.type === "BrandingDarkBgColor") {
          setDarkBackgroundColor(data)
        }
        if (data.type === "BrandingAccentLighterColor") {
          setAccentLightColor(data)
        }
        if (data.type === "BrandingAccentDarkerColor") {
          setAccentDarkColor(data)
        }
        if (data.type === "BrandingFont") {
          setFont(data)
        }
      })
    })
  }

  useEffect(() => {
    setBrandingDetails()
  }, [])

  return (
    <Accordion.Item eventKey="6">
      <Accordion.Header>
        <i className="bi bi-brush-fill me-2" /> Public Portal & Branding
      </Accordion.Header>
      <Accordion.Body>
        <div className="row p-3">
          <div className="col-lg-10 col-sm-12 col-md-12 col-xs-12 mx-auto">
            <div className="row">
              <div className="col-lg-8 col-sm-12 col-md-12 col-xs-12 mx-auto">
                <div className="row mb-3 align-items-center">
                  <div className="col-md-4 setting_label">
                    <label htmlFor="branding_flag" className="col-form-label">
                      Company Branding is
                    </label>
                  </div>
                  <div className="col-md-8 adbook-user-info">
                    <div className="toggleSwitch">
                      <input
                        type="checkbox"
                        name="company_branding"
                        id="company_branding"
                        key={brandingSettingValue}
                        defaultChecked={brandingSettingValue === "On"}
                        className="company_branding general_switch"
                        onChange={handleSubmit}
                      />
                      <label />
                    </div>
                  </div>
                </div>

                {brandingSettingValue === "On" && (
                  <BrandingSetting
                    lightBackgroundLogo={lightBackgroundLogo}
                    darkBackgroundLogo={darkBackgroundLogo}
                    favicon={favicon}
                    customBackground={customBackground}
                    lightBackgroundColor={lightBackgroundColor}
                    darkBackgroundColor={darkBackgroundColor}
                    accentLightColor={accentLightColor}
                    accentDarkColor={accentDarkColor}
                    font={font}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default Branding
