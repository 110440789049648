import React, { useEffect, useState } from "react"
import { save } from "../helper"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const AccentDarkColor = ({ data }) => {
  const [value, setValue] = useState("#196ABC"),
    [id, setId] = useState(null),
    [tempColor, setTempColor] = useState("")

  const handleChange = event => {
    setTempColor(event.target.value)
  }

  const handleSubmit = e => {
    if (value !== e.target.value) {
      setValue(e.target.value)
      save({ id, type: "BrandingAccentDarkerColor", value: e.target.value, setValue, setId })
    }
  }

  useEffect(() => {
    if (id === null && data) {
      setId(data.id)
      setValue(data.value)
    }
  })

  return (
    <div className="row mb-3 align-items-center company_accent_color_dark_container">
      <div className="col-md-4 setting_label">
        <label htmlFor="accent_color_dark" className="col-form-label">
          Accent Color (Darker Version)
        </label>{" "}
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip className="ms-1 d-inline-block">White font should look good over this.</Tooltip>}
        >
          <i className="bi bi-question-circle-fill" />
        </OverlayTrigger>
      </div>
      <div className="col-md-8">
        <input
          type="color"
          className="form-control form-control-color"
          id="accent_color_dark"
          name="accent_color_dark"
          value={tempColor === "" ? value : tempColor}
          title="Choose your color"
          onChange={handleChange}
          onMouseLeave={handleSubmit}
        />
      </div>
    </div>
  )
}

export default AccentDarkColor
