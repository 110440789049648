import React, { useEffect, useState } from "react"
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap"
import AxiosInstance from "../../../../services/axiosInstance"

const BusinessLocation = () => {
  const [businessLocation, setBusinessLocation] = useState(""),
    [businessLocationId, setBusinessLocationId] = useState(null),
    [showBusinessLocationForm, setShowBusinessLocationForm] = useState(false),
    [hideForRemoteOnly, setHideForRemoteOnly] = useState(""),
    [businessAddress, setBusinessAddress] = useState(""),
    [addressData, setAddressData] = useState([]),
    [latestAddressType, setLatestAddressType] = useState(""),
    [addressEditId, setAddressEditId] = useState(null),
    [addressEditType, setAddressEditType] = useState(null)

  const getBusinessLocation = async () => {
    AxiosInstance.get("/settings/general-setting/unique-data").then(response => {
      response.data.uniqueData.map(data => {
        if (data.type === "BusinessLocation") {
          setBusinessLocationId(data.id)
          setBusinessLocation(data.value)
        }
      })
    })
  }

  const handleBusinessLocation = e => {
    setBusinessLocation(e.target.value)

    let url = "/settings/general-setting/add"
    if (businessLocationId) {
      url = `/settings/general-setting/edit/${businessLocationId}`
    }
    AxiosInstance.post(url, {
      type: "BusinessLocation",
      value: e.target.value,
      privacy: "public",
    })
      .then(response => {
        setBusinessLocation(response.data.value)
        getBusinessLocation()
      })
      .catch(function (error) {
        console.log("error", error)
      })
  }

  function generateNewLocationType(lastLocationType) {
    if (!lastLocationType) {
      return "location_001"
    }

    const match = lastLocationType.match(/(.*_)(\d+)$/)
    if (match) {
      const [, prefix, number] = match
      const newNumber = parseInt(number, 10) + 1
      const newKey = `${prefix}${newNumber.toString().padStart(3, "0")}`
      return newKey
    } else {
      throw new Error("The existing key does not match the expected format.")
    }
  }

  const saveBusinessAddress = e => {
    e.preventDefault()

    if (!businessAddress) {
      alert("Please enter address.")
      return false
    }

    var newAddressType = generateNewLocationType(latestAddressType)
    var url = "/settings/general-setting/add"
    var requestData = {
      type: newAddressType,
      value: businessAddress,
      privacy: "public",
    }
    if (addressEditId) {
      var url = `/settings/general-setting/edit/${addressEditId}`
      var requestData = {
        type: addressEditType,
        value: businessAddress,
        privacy: "public",
      }
    }

    AxiosInstance.post(url, requestData)
      .then(response => {
        getBusinessAddress()
        setBusinessAddress("")
      })
      .catch(function (error) {
        console.log("error", error)
      })
  }

  const handleEditAddress = address => {
    setShowBusinessLocationForm(true)
    setBusinessAddress(address.value)
    setAddressEditId(address.id)
    setAddressEditType(address.type)
  }

  const handleAddNewAddress = () => {
    setShowBusinessLocationForm(true)
    setBusinessAddress("")
    setAddressEditId("")
    setAddressEditType("")
  }

  const handleDeleteAddress = addressId => {
    AxiosInstance.delete(`/settings/general-setting/${addressId}`)
      .then(response => {
        if (response.status === 200) {
          getBusinessAddress()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getBusinessAddress = async () => {
    AxiosInstance.get("/settings/general-setting/location-address").then(response => {
      const allAddressData = response.data.data
      setAddressData(allAddressData)
      const lastAddress = allAddressData[allAddressData.length - 1]
      setLatestAddressType(lastAddress?.type)
    })
  }

  useEffect(() => {
    if (businessLocation === "remoteOnly") {
      setHideForRemoteOnly(" d-none")
    } else {
      setHideForRemoteOnly("")
    }
  }, [businessLocation])

  useEffect(() => {
    getBusinessLocation()
    getBusinessAddress()
  }, [])

  return (
    <Accordion.Item eventKey="10">
      <Accordion.Header data-bs-target="#BusinessLocationsSetting" aria-controls="BusinessLocationsSetting">
        <i className="bi bi-geo-alt-fill me-2" />
        Business Locations{" "}
      </Accordion.Header>
      <div
        id="BusinessLocationsSetting"
        className="accordion-collapse collapse show"
        aria-labelledby="BusinessLocationsSetting"
        data-bs-parent="#SwiftfCloudSettings"
        style={{}}
      >
        <Accordion.Body>
          <div className="row p-3">
            <div className="col-lg-10 col-sm-12 col-md-12 col-xs-12 mx-auto">
              <div className="row row-cols-sm-auto g-2 mb-3 align-items-center">
                <div className="col-12">We serve clients at</div>
                <div className="col-12">
                  <select
                    name="Weserveclientsat"
                    id="Weserveclientsat"
                    className="form-select"
                    value={businessLocation}
                    onChange={handleBusinessLocation}
                  >
                    <option value={"theirLocation"}>Their Location - we go to them</option>
                    <option value={"ourLocations"}>Our Locations, they come to us</option>
                    <option value={"eitherOrBoth"}>Either / Both</option>
                    <option value={"remoteOnly"}>Remote Only / Doesn't Apply</option>
                  </select>
                </div>
              </div>
              <div className={`row${hideForRemoteOnly}`}>
                <div
                  className={`col-lg-6 col-md-8 mx-auto py-4${!showBusinessLocationForm ? " d-none" : ""}`}
                  id="addNewBusinessLocationContainer"
                >
                  <div className="row">
                    <div className="col-lg-12 colHeading">
                      <strong>
                        <i className="bi bi-plus-lg" /> Add Business Location
                      </strong>
                    </div>
                    <div className="col-lg-12 dh-block-body">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="row mb-3 align-items-center">
                                <div className="col-md-4">
                                  Service Location{" "}
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        For Service Radius, we'll show just a circle of your area. We won't
                                        show your address.
                                      </Tooltip>
                                    }
                                  >
                                    <i className="bi bi-question-circle-fill ms-1" />
                                  </OverlayTrigger>
                                </div>
                                <div className="col-md-8">
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Address"
                                      value={businessAddress}
                                      onChange={e => setBusinessAddress(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 text-end">
                                  <button
                                    type="button"
                                    name="btnCancel"
                                    id="btnCancelAddNewBusinessLocation"
                                    className="btn btn-default"
                                    onClick={() => {
                                      setShowBusinessLocationForm(false)
                                    }}
                                  >
                                    <i className="bi bi-x-lg" /> Cancel
                                  </button>{" "}
                                  <button
                                    type="button"
                                    name="btnSave"
                                    id="btnSaveBusinessLocation"
                                    className="btn btn-primary"
                                    onClick={saveBusinessAddress}
                                  >
                                    <i className="bi bi-check2" /> Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`row mb-2 align-items-center${hideForRemoteOnly}`}>
                <div className="col-md-6" />
                <div className="col-md-6 text-end">
                  <button
                    className="btn btn-primary btnAddNewBusinessLocation"
                    onClick={() => handleAddNewAddress()}
                  >
                    <i className="bi bi-plus-lg" /> Add New
                  </button>
                </div>
              </div>
              <div className={`table-responsive${hideForRemoteOnly}`}>
                <table
                  id="tblBusinessLocations"
                  className="table table-striped dataTable no-footer"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th width="80%">Address</th>
                      <th width="20%" />
                    </tr>
                  </thead>
                  <tbody>
                    {addressData.length === 0 ? (
                      <tr>
                        <td colSpan="2" className="text-center">
                          No location address added yet - Want to{" "}
                          <a
                            href="#"
                            style={{ color: "rgb(18, 95, 173)", cursor: "pointer" }}
                            onClick={() => handleAddNewAddress()}
                          >
                            click here to add one
                          </a>{" "}
                          now?
                        </td>
                      </tr>
                    ) : (
                      addressData.map((address, index) => (
                        <tr key={index}>
                          <td>{address.value}</td>
                          <td className="text-end tableColAction d-none d-md-table-cell">
                            <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                              <a
                                className="btn btn-edit"
                                onClick={() => {
                                  handleEditAddress(address)
                                }}
                              >
                                <i className="bi bi-pencil-fill" />
                              </a>
                            </OverlayTrigger>{" "}
                            <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                              <a className="btn btn-delete" onClick={() => handleDeleteAddress(address.id)}>
                                <i className="bi bi-trash-fill" />
                              </a>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Accordion.Body>
      </div>
    </Accordion.Item>
  )
}

export default BusinessLocation
