import React from "react"

import PrivateRoute from "../../components/privateRoute"
import BusinessDetails from "../../components/Settings/bussiness"

const Business = props => {
  return <PrivateRoute component={BusinessDetails} location={props.location} />
}

export default Business
