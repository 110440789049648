import React, { useEffect, useState } from "react"
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap"

import EditCustomContact from "./editCustomContact"
import AxiosInstance from "../../../../services/axiosInstance"
import { CUSTOM_TEXTBOX } from "../../../Contacts/contactHelper"

const CustomContact = ({ icons }) => {
  const [customContactForm, setCustomContactForm] = useState(false),
    [customContactTypes, setCustomContactTypes] = useState([]),
    [customContactType, setCustomContactType] = useState(""),
    [reloadData, setReloadData] = useState(false),
    [fieldName, setFieldName] = useState(""),
    [fieldIcon, setFieldIcon] = useState(""),
    [fieldType, setFieldType] = useState(CUSTOM_TEXTBOX)

  const handleAdd = () => {
    setCustomContactForm(true)
    setCustomContactType("")
    setFieldName("")
    setFieldIcon("bi bi-0-circle")
    setFieldType(CUSTOM_TEXTBOX)
  }
  const handleEditForm = id => {
    setCustomContactForm(true)
    let filterData = customContactTypes.filter(data => {
      return data.id === id
    })
    setCustomContactType(filterData[0])
    setFieldName(filterData[0].label)
    setFieldIcon(filterData[0].icon)
    setFieldType(filterData[0].type)
  }

  const customContactTypesData = () => {
    AxiosInstance.get("/settings/custom-field")
      .then(response => {
        setCustomContactTypes(response.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleDelete = Id => {
    AxiosInstance.delete(`/settings/custom-field/${Id}`)
      .then(response => {
        if (response.status === 200) {
          customContactTypesData()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    customContactTypesData()
  }, [])

  useEffect(() => {
    if (reloadData === true) {
      customContactTypesData()
      setReloadData(false)
    }
  })

  return (
    <Accordion.Item eventKey="2">
      <Accordion.Header data-bs-target="#customFieldSetting" aria-controls="customFieldSetting">
        <i className="bi bi-person-fill me-2" />
        Custom Contact Fields{" "}
        <span>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>What key info do you need about most clients?</Tooltip>}
          >
            <i className="bi bi-question-circle-fill ms-1" />
          </OverlayTrigger>
        </span>
      </Accordion.Header>
      <Accordion.Body id="customFieldSetting">
        <div className="row p-3">
          <div className="col-lg-10 col-sm-12 col-md-12 col-xs-12 mx-auto">
            {customContactForm === true && (
              <EditCustomContact
                setCustomContactForm={setCustomContactForm}
                customContactType={customContactType}
                setReloadData={setReloadData}
                icons={icons}
                fieldName={fieldName}
                setFieldName={setFieldName}
                fieldType={fieldType}
                setFieldType={setFieldType}
                fieldIcon={fieldIcon}
                setFieldIcon={setFieldIcon}
              />
            )}

            <div className="row mb-2 align-items-center">
              <div className="col-md-6" />
              <div className="col-md-6 text-end">
                <button className="btn btn-primary btnAddNewCustomField" onClick={handleAdd}>
                  <i className="bi bi-plus-lg" />
                  Add New
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped dataTable no-footer" id="tblCustomField" width="100%">
                <thead>
                  <tr>
                    <th width="40%">Name/Label</th>
                    <th width="40%">Type</th>
                    <th width="20%" />
                  </tr>
                </thead>
                <tbody>
                  {customContactTypes.length === 0 && (
                    <tr>
                      <td colSpan={3} className="text-center">
                        No custom contact fields added yet. Want to
                        <a
                          style={{ color: "#125fad", cursor: "pointer" }}
                          onClick={() => setCustomContactForm(true)}
                        >
                          {" "}
                          click here to add one{" "}
                        </a>{" "}
                        now?
                      </td>
                    </tr>
                  )}
                  {customContactTypes.map((customContactType, index) => {
                    return (
                      <tr key={index}>
                        <td>{customContactType.label}</td>
                        <td>{customContactType.type}</td>
                        <td className="text-end tableColAction d-none d-md-table-cell">
                          <a
                            className="btn btn-edit"
                            onClick={() => {
                              handleEditForm(customContactType.id)
                            }}
                          >
                            <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                              <i className="bi bi-pencil-fill" />
                            </OverlayTrigger>
                          </a>{" "}
                          <a
                            className="btn btn-delete"
                            onClick={() => {
                              handleDelete(customContactType.id)
                            }}
                          >
                            <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                              <i className="bi bi-trash-fill" />
                            </OverlayTrigger>
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default CustomContact
