import React, { useState } from "react"
import TagRoleField from "./tagRoleField"
import AxiosInstance from "../../../../services/axiosInstance"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const AddRaveScore = ({ roleForm, setRoleForm, setTableLoad, filterRoleTableId }) => {
  const [role, setRole] = useState("")
  const [addTag, setAddTag] = useState(false),
    [newTag, setNewTag] = useState(""),
    [tempTags, setTempTags] = useState([]),
    [removeTagIndex, setRemoveTagIndex] = useState(null)

  const handleSave = () => {
    AxiosInstance.post("/settings/role/add", {
      role: role,
      tags: tempTags,
    })
      .then(response => {
        setRoleForm(false)
        setTableLoad(true)
        setRole("")
        setTempTags([])
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <div className="row">
      <div className="col-lg-6 col-md-8 mx-auto py-4" id="addNewRoleContainer">
        <div className="row">
          <div className="col-lg-12 colHeading">
            <strong>
              <i className="bi bi-plus-lg" />
              Add Role
            </strong>
          </div>
          <div className="col-lg-12 dh-block-body">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row mb-3 align-items-center">
                      <div className="col-sm-4">
                        Role{" "}
                        <span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>"i.e. Patient, Borrower, Client, Customer... just for you."</Tooltip>
                            }
                          >
                            <i className="bi bi-question-circle-fill ms-1"></i>
                          </OverlayTrigger>
                        </span>
                      </div>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          name="role_field"
                          id="role_field"
                          className="form-control"
                          value={role}
                          onChange={e => {
                            setRole(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <TagRoleField
                      addTag={addTag}
                      setAddTag={setAddTag}
                      newTag={newTag}
                      setNewTag={setNewTag}
                      tempTags={tempTags}
                      setTempTags={setTempTags}
                      removeTagIndex={removeTagIndex}
                      setRemoveTagIndex={setRemoveTagIndex}
                      filterRoleTableId={filterRoleTableId}
                    />
                    <div className="row">
                      <div className="col-sm-12 text-end">
                        <button
                          type="button"
                          name="btnCancel"
                          id="btnCancelNewRole"
                          className="btn btn-default"
                          onClick={() => setRoleForm(false)}
                        >
                          <i className="bi bi-x-lg"></i>
                          Cancel
                        </button>{" "}
                        <button
                          type="button"
                          name="btnSave"
                          id="btnSaveNewRole"
                          className="btn btn-primary"
                          onClick={handleSave}
                        >
                          <i className="bi bi-check2"></i>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AddRaveScore
